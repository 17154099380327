'use client';

import { useEffect, useState } from 'react';
import styles from './DebugPanel.module.scss';

export const DebugPanel: React.FC = ({}) => {
  const [throwRenderError, setThrowRenderError] = useState(false);
  const [throwEffectError, setThrowEffectError] = useState(false);

  if (throwRenderError) {
    throw new Error('Test Render Error');
  }

  useEffect(() => {
    if (throwEffectError) {
      throw new Error('Test Effect Error');
    }
  }, [throwEffectError]);

  return (
    <div className={styles.panel}>
      <button onClick={() => setThrowRenderError(true)}>Render Error</button>
      <button
        onClick={() => {
          throw new Error('Test Event Handler Error');
        }}
      >
        Event Handler Error
      </button>
      <button onClick={() => setThrowEffectError(true)}>Effect Error</button>
    </div>
  );
};
